import {Pipe, PipeTransform} from '@angular/core'
import {formatLocalDate} from "../../../formatters/src/lib/format-local-date"

@Pipe({
  name: 'localDate',
  standalone: true,
})
export class LocalDatePipe implements PipeTransform {
  transform(value: string | number): string {
    return formatLocalDate(value)
  }
}
