import {environment} from '@env'
import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {logger} from 'nx/src/utils/logger'
import {catchError, Observable, of} from 'rxjs'
import {DriverModel, ResponsibilityModel, ResponsibleModel, VResponsibilityModel} from '@domain/driver'

@Injectable({
  providedIn: 'root',
})
export class DriverService {

  constructor(
      private http: HttpClient,
  ) {
  }

  getDrivers(): Observable<DriverModel[]> {
    const url = `${environment.apiUrl}/drivers`
    return this.http.get<DriverModel[]>(url)
  }

  getActiveDrivers(): Observable<DriverModel[]> {
    const url = `${environment.apiUrl}/drivers?active=true`
    return this.http.get<DriverModel[]>(url)
  }

  getVResponsibilities(): Observable<VResponsibilityModel[]> {
    const url = `${environment.apiUrl}/responsibilities`
    return this.http.get<VResponsibilityModel[]>(url)
  }

  getResponsibilitiesFromCurrentWeek(): Observable<ResponsibilityModel[]> {
    const url = `${environment.apiUrl}/responsibilities/from-current-week`
    return this.http.get<ResponsibilityModel[]>(url)
  }

  getVResponsibilitiesByDayWeek(date: string): Observable<VResponsibilityModel[]> {
    const url = `${environment.apiUrl}/responsibilities/from-day-week/${date}`
    return this.http.get<VResponsibilityModel[]>(url)
  }

  getResponsibilitiesByDateFrom(date: string): Observable<ResponsibilityModel[]> {
    const url = `${environment.apiUrl}/responsibilities/from-day/${date}`
    return this.http.get<ResponsibilityModel[]>(url)
  }

  getResponsibilitiesByDateFromAsc(date: string): Observable<ResponsibilityModel[]> {
    const url = `${environment.apiUrl}/responsibilities/from-day/${date}?order=asc`
    return this.http.get<ResponsibilityModel[]>(url)
  }

  getResponsibilitiesByDateFromDesc(date: string): Observable<ResponsibilityModel[]> {
    const url = `${environment.apiUrl}/responsibilities/from-day/${date}?order=desc`
    return this.http.get<ResponsibilityModel[]>(url)
  }

  getVResponsibilitiesById(id: string): Observable<VResponsibilityModel[]> {
    const url = `${environment.apiUrl}/responsibilities/${id}`
    return this.http.get<VResponsibilityModel[]>(url)
  }


  // add(responsibility: ResponsibilityModel): Observable<ResponsibilityModel> {
  //   const url = `${environment.apiUrl}/responsibilities`
  //   return this.http.post<ResponsibilityModel>(url, responsibility)
  addResponsibility(responsibility: ResponsibilityModel): Observable<number> {
    const url = `${environment.apiUrl}/responsibilities`
    return this.http.post<number>(url, responsibility)
    /*
     return this.http.post<ResponsibilityModel>(url, responsibility).pipe(
     catchError(this.handleError('addResponsibility', responsibility)),
     )
     */
  }

  updateResponsibility(responsibility: ResponsibilityModel): Observable<boolean> {
    const url = `${environment.apiUrl}/responsibilities`
    return this.http.put<boolean>(url, responsibility)
    /*
     return this.http.put<boolean>(url, responsibility).pipe(
     catchError(this.handleError('updateResponsibility', responsibility)),
     )
     */
  }

  deleteResponsibilitiesByIds(ids: number[]): Observable<unknown> {
    const url = `${environment.apiUrl}/responsibilities/${ids}`
    logger.debug('delete responsibilities: ids=', ids, 'url=', url)
    return this.http
               .delete(url)
               .pipe(
                   catchError(this.handleError('deleteResponsibility')),
               )
  }

  // handleError(error: HttpErrorResponse, responsible?: ResponsibilityModel): Observable<ResponsibilityModel> {
  /*
   private handleError<T>(operation = 'operation', result?: T) {
   return (error: any): Observable<T> => {
   // TODO: send the error to remote logging infrastructure
   console.error(error) // log to console instead
   // TODO: better job of transforming error for user consumption
   this.log(`${operation} failed: ${error.message}`)
   // Let the app keep running by returning an empty result.
   return of(result as T)
   }
   }
   */

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<boolean> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error) // log to console instead
      // TODO: better job of transforming error for user consumption
      logger.error(`${operation} failed: ${error.message}`)
      // Let the app keep running by returning an empty result.
      return of(false)
    }
  }

  getResponsibles(): Observable<ResponsibleModel[]> {
    const url = `${environment.apiUrl}/responsibles`
    return this.http.get<ResponsibleModel[]>(url)
  }

  getResponsiblesByContactId(id: number): Observable<ResponsibleModel[]> {
    const url = `${environment.apiUrl}/responsibles/contact-id/${id}`
    return this.http.get<ResponsibleModel[]>(url)
  }

  getResponsiblesByUserId(id: number): Observable<ResponsibleModel[]> {
    const url = `${environment.apiUrl}/responsibles/user-id/${id}`
    return this.http.get<ResponsibleModel[]>(url)
  }

}
